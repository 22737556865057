<template>
  <div class="page-wrap">
    <div class="session-form-hold">
      <base-card :loading="loading" class="rounded-lg">
        <v-card-text class="text-center">
          <img
            v-if="$vuetify.theme.dark"
            class="mb-4"
            height="50"
            src="@/assets/images/logo/logo-light.png"
            alt=""
          />
          <img
            v-else
            class="mb-4"
            height="50"
            src="@/assets/images/logo/logo-dark.png"
            alt=""
          />
          <template v-if="!loading && !error">
            <h5>Bora criar uma conta?</h5>
            <h6 class="text--disabled font-weight-medium mb-10">
              Preencha os dados para continuar
            </h6>

            <v-form v-model="valid" @submit.prevent="processRecaptcha(true)">
              <v-card
                outlined
                class="pa-3 d-flex rounded-lg mb-4 gap-4 align-center text-start"
                @click="whatsappModal = true"
              >
                <div>
                  <v-avatar color="grey" v-if="!importWhatsappPhoto">
                    <v-icon color="white">mdi-help</v-icon>
                  </v-avatar>
                  <v-avatar color="success" v-else>
                    <v-icon color="white">mdi-whatsapp</v-icon>
                  </v-avatar>
                </div>
                <div>
                  <h6 class="mb-0">
                    {{ user.phone }}
                  </h6>
                  <span v-if="!importWhatsappPhoto">
                    Importar foto do WhatsApp?
                  </span>
                  <span v-else class="success--text">
                    Sua foto do WhatsApp será importada.
                  </span>
                </div>
              </v-card>

              <v-text-field
                label="Nome"
                v-model="user.name"
                :rules="nameRules"
                :disabled="innerLoading"
                dense
                outlined
              />
              <v-text-field
                label="Email"
                v-model="user.email"
                :rules="emailRules"
                :disabled="innerLoading"
                dense
                outlined
              />

              <div class="d-flex gap-1">
                <v-menu v-if="user.ddi != 55" offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="disabled"
                      elevation="0"
                      v-bind="attrs"
                      v-on="on"
                      @click="focusFilter"
                      class="px-0"
                      min-width="35px"
                      min-height="41px"
                    >
                      <v-icon small>{{
                        documentTypes[user.documentType].icon
                      }}</v-icon>
                    </v-btn>
                  </template>
                  <v-card class="pa-1">
                    <v-list style="overflow-y: auto" class="py-0">
                      <v-list-item
                        v-for="[key, item] in Object.entries(documentTypes)"
                        :key="key"
                        @click="() => (user.documentType = key)"
                        class="rounded px-3"
                        :class="{
                          primary: key === user.documentType,
                          'white--text': key === user.documentType,
                        }"
                      >
                        <v-list-item-content class="py-0">
                          <v-list-item-title class="d-flex align-center">
                            <v-icon
                              left
                              :color="key === user.documentType ? 'white' : ''"
                              >{{ item.icon }}</v-icon
                            >
                            {{ item.label }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-menu>
                <v-text-field-simplemask
                  v-model="user.document"
                  :label="documentTypes[user.documentType].label"
                  class="flex-grow-1"
                  v-bind:properties="{
                    prefix: '',
                    suffix: '',
                    outlined: true,
                    placeholder: documentTypes[user.documentType].mask,
                    type: 'tel',
                    rules: documentTypes[user.documentType].rules,
                    disabled: innerLoading,
                    dense: true,
                  }"
                  v-bind:options="{
                    inputMask: documentTypes[user.documentType].mask,
                    outputMask: documentTypes[user.documentType].mask,
                    empty: '',
                    applyAfter: false,
                    alphanumeric: documentTypes[user.documentType].alphanumeric,
                    lowerCase: false,
                  }"
                />
              </div>

              <v-text-field-simplemask
                v-model="user.birthday"
                label="Data de nascimento"
                v-bind:properties="{
                  prefix: '',
                  suffix: '',
                  outlined: true,
                  placeholder: 'DD/MM/AAAA',
                  type: 'tel',
                  rules: birthdayRules,
                  disabled: innerLoading,
                  dense: true,
                }"
                v-bind:options="{
                  inputMask: '##/##/####',
                  outputMask: '##/##/####',
                  empty: '',
                  applyAfter: false,
                  alphanumeric: false,
                  lowerCase: false,
                }"
              ></v-text-field-simplemask>

              <v-text-field
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show ? 'text' : 'password'"
                name="input-10-2"
                label="Senha"
                v-model="user.password"
                :disabled="innerLoading"
                @click:append="show = !show"
                :rules="passwordRules"
                outlined
                dense
              />
              <v-text-field
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show ? 'text' : 'password'"
                name="input-10-2"
                label="Confirme a senha"
                v-model="confirmPassword"
                outlined
                dense
                :rules="[
                  (v) => !!v || 'Confirmação da senha é obrigatória',
                  (v) => v === user.password || 'Senhas não conferem',
                ]"
                :disabled="innerLoading"
                @click:append="show = !show"
              />
              <v-checkbox
                v-model="acceptTerms"
                :disabled="innerLoading"
                class="mt-0 mb-2"
                :rules="[
                  (v) =>
                    v ||
                    'Você precisa aceitar os termos de uso e política de privacidade',
                ]"
              >
                <template v-slot:label>
                  <div>
                    Eu li e aceito os
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <a
                          target="_blank"
                          href="/terms/contract"
                          @click.stop
                          v-on="on"
                        >
                          termos de uso
                        </a>
                      </template>
                      <span>
                        <v-icon left small color="white"
                          >mdi-open-in-new</v-icon
                        >
                        Abrir em nova aba
                      </span>
                    </v-tooltip>
                    e
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <a
                          target="_blank"
                          href="/terms/privacy"
                          @click.stop
                          v-on="on"
                        >
                          política de privacidade
                        </a>
                      </template>
                      <span>
                        <v-icon left small color="white"
                          >mdi-open-in-new</v-icon
                        >
                        Abrir em nova aba
                      </span>
                    </v-tooltip>
                  </div>
                </template>
              </v-checkbox>
              <v-alert
                v-if="innerError"
                border="left"
                class="text-left"
                text
                icon="mdi-alert-circle-outline"
                type="error"
              >
                {{ innerError }}
              </v-alert>
              <v-btn
                class="mb-4"
                block
                color="primary"
                :loading="innerLoading"
                :disabled="!valid"
                type="submit"
              >
                Cadastrar
              </v-btn>
            </v-form>
          </template>
          <template v-else-if="!error">
            <v-alert
              border="left"
              class="text-left"
              text
              icon="mdi-information-outline"
              type="info"
            >
              Aguarde enquanto o nosso sistema verifica seu WhatsApp.
            </v-alert>
          </template>
          <template v-else>
            <v-alert
              border="left"
              class="text-left"
              text
              icon="mdi-alert-circle-outline"
              type="error"
            >
              {{ error }}
            </v-alert>
            <v-btn
              color="secondary"
              class="mt-4"
              block
              :to="{
                name: 'sign-in',
              }"
            >
              Voltar
            </v-btn>
          </template>
        </v-card-text>
      </base-card>
    </div>

    <v-dialog v-model="whatsappModal" max-width="450px">
      <v-card>
        <!-- <v-card-title class="text-h6">
         
        </v-card-title> -->
        <v-card-text class="pt-4">
          <h6>Deseja importar sua foto do WhatsApp?</h6>
          <v-alert border="left" class="mb-0" text color="info">
            <p class="font-weight-medium mb-1">
              Podemos usar a sua foto de perfil do WhatsApp para atualizar a
              foto do seu perfil aqui.
            </p>
            <small>
              Para isso, basta adicionar nosso número à sua lista de contatos no
              WhatsApp. Assim, poderemos localizá-lo e importar a foto do seu
              perfil.
            </small>
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            @click="
              () => {
                whatsappModal = false;
                importWhatsappPhoto = false;
              }
            "
          >
            Não, obrigado
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="
              () => {
                whatsappModal = false;
                importWhatsappPhoto = true;
              }
            "
          >
            Sim, importar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <re-captcha
      ref="recaptcha"
      @success="execute"
      auto-run
      @loaded="processRecaptcha"
      @error="recaptchaError"
      @close="recaptchaError"
    />
  </div>
</template>
<script>
import validateCpf from "@/utils/validate-cpf";
import ReCaptcha from "@/components/global/ReCaptcha";
import AUTH from "@/services/auth";
import { mapActions } from "vuex";
import passwordRules from "@/utils/password-rules";
import moment from "moment";

export default {
  name: "SignUp",
  metaInfo: {
    title: "Criar Conta",
  },
  data() {
    return {
      show: false,
      loading: true,
      error: false,
      innerLoading: false,
      innerError: false,
      menu: false,
      valid: false,
      user: {
        phone: "(14) 99999-9999",
        name: "",
        document: "",
        documentType: "CPF",
        email: "",
        password: "",
        birthday: null,
      },
      confirmPassword: "",
      acceptTerms: false,
      whatsappModal: false,
      importWhatsappPhoto: false,
      passwordRules: passwordRules(),
      documentTypes: {
        CPF: {
          icon: "mdi-id-card",
          label: "CPF",
          mask: "###.###.###-##",
          alphanumeric: false,
          rules: [
            (v) => !!v || "CPF é obrigatório",
            (v) => validateCpf(v) || "CPF inválido",
          ],
        },
        PASSPORT: {
          icon: "mdi-passport",
          label: "Passaporte",
          mask: "#########",
          alphanumeric: true,
          rules: [
            (v) => !!v || "Passaporte é obrigatório",
            (v) => (v.length >= 6 && v.length <= 9) || "Passaporte inválido",
          ],
        },
        EXTRANGER: {
          icon: "mdi-card-account-details",
          label: "Documento estrangeiro",
          placeholder: "Documento estrangeiro",
          mask: "##################",
          rules: [
            (v) => !!v || "Documento estrangeiro é obrigatório",
            (v) => v.length >= 5 || "Documento estrangeiro inválido",
          ],
        },
      },

      emailRules: [
        (v) => !!v || "Email é obrigatório",
        (v) =>
          /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(
            v
          ) || "Email inválido",
      ],
      nameRules: [
        (v) => !!v || "Nome é obrigatório",
        (v) => v.length >= 3 || "Nome deve ter no mínimo 3 caracteres",
        (v) =>
          v.trim().split(" ").length >= 2 || "Nome deve ter no mínimo 2 nomes",
      ],
      birthdayRules: [
        (v) => !!v || "A data de nascimento é obrigatória",
        (v) => this.validateAndCheckDate(v) || "Data inválida ou no futuro",
      ],
    };
  },
  computed: {
    formattedBirthday: {
      get() {
        if (!this.user.birthday) return "";
        const [year, month, day] = this.user.birthday.split("-");
        return `${day}/${month}/${year}`;
      },
      set(value) {
        const [day, month, year] = value.split("/");
        this.user.birthday = `${year}-${month}-${day}`;
      },
    },
  },
  methods: {
    ...mapActions("auth", ["setLogin"]),
    recaptchaError() {
      if (this.loading) {
        this.loading = false;
        this.error =
          "Erro ao validar recaptcha, atualize a página e tente novamente.";
      } else {
        this.innerLoading = false;
        this.innerError = "Erro ao validar recaptcha.";
      }
    },
    processRecaptcha(force) {
      this.innerLoading = true;
      this.innerError = false;
      if (force) this.$refs.recaptcha.execute();
    },

    validateAndCheckDate(dateString) {
      const date = moment(dateString, "DD/MM/YYYY");
      if (!date.isValid()) {
        return false;
      }
      return date.isSameOrBefore(moment(), "day");
    },
    execute(token) {
      if (this.f) {
        this.f(token);
      }
    },
    async register(recaptcha) {
      try {
        this.innerLoading = true;
        this.innerError = false;
        const userCopy = { ...this.user };

        if (userCopy.birthday) {
          userCopy.birthday = moment(
            userCopy.birthday,
            "DD/MM/YYYY"
          ).toISOString();
        }

        let token = this.$route.query.token;
        const response = await AUTH.register(
          { ...userCopy, token, importPhoto: this.importWhatsappPhoto },
          recaptcha
        );
        await this.setLogin(response);

        const redirect = response.extraData?.redirect;
        this.$router.push(redirect || "/app");
      } catch (e) {
        this.innerError = e.message;
      } finally {
        this.innerLoading = false;
      }
    },
    async readToken(recaptcha) {
      try {
        let token = this.$route.query.token;
        let response = await AUTH.readToken(token, recaptcha);
        this.user.phone = response.user.phone;
        this.user.ddi = response.user.ddi;

        if (response.user.ddi !== 55) this.user.documentType = "PASSPORT";

        this.loading = false;
        this.innerLoading = false;
        this.f = this.register;
      } catch (e) {
        this.loading = false;
        this.error = e.message;
      }
    },
    formatName(name) {
      return name
        .toLowerCase()
        .split(" ")
        .map((n, i) =>
          n.length > 3 || !i ? n.charAt(0).toUpperCase() + n.slice(1) : n
        )
        .join(" ");
    },
  },
  watch: {
    "user.name": {
      handler: function (newValue) {
        if (newValue && newValue.length > 0)
          this.user.name = this.formatName(newValue);
      },
      immediate: true,
    },
    "user.email": {
      handler: function (newValue) {
        if (newValue && newValue.length > 0)
          this.user.email = this.user.email.toLowerCase();
      },
      immediate: true,
    },
  },
  mounted() {
    let token = this.$route.query.token;
    if (!token) {
      this.loading = false;
      this.error =
        "Você precisa acessar essa página a partir do link enviado para seu WhatsApp. Não recebeu o link? Clique em voltar e tente novamente.";
    } else {
      this.f = this.readToken;
    }
  },
  components: {
    ReCaptcha,
  },
};
</script>
<style lang="scss" scoped>
.page-wrap {
  background: radial-gradient(
      circle at 50% 50%,
      rgba(0, 0, 0, 50%) 0%,
      rgba(0, 0, 0, 90%) 90vw
    ),
    url(/img/logo/auth-background.png), black;
  display: flex;
  background-size: cover;
  background-position: center;
  align-items: center;
  padding: 40px 1rem;
  height: 100%;
  min-height: 100vh;
}
.session-form-hold {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}
</style>
